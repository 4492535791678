import { useMutation, useQueryClient } from '@tanstack/react-query'
import { InterventionPayload } from 'services/occurrence/types'
import OccurrenceService from 'services/occurrence'

function createIntervention(
  occurrenceId: string,
  data: InterventionPayload,
): Promise<void> {
  return OccurrenceService.createIntervention(occurrenceId, data)
}
interface usePostIntervention {
  occurrenceId?: string
  onSuccessCallback?: (data: InterventionPayload) => void
}

export default function usePostIntervention({
  occurrenceId,
  onSuccessCallback,
}: usePostIntervention) {
  const queryClient = useQueryClient()

  const { status, mutate } = useMutation({
    mutationFn: async (data: InterventionPayload) => {
      if (occurrenceId) {
        return await createIntervention(occurrenceId, data)
      }
    },

    retry: false,
    onSuccess: async (_data, variables) => {
      onSuccessCallback?.(variables)
      queryClient.invalidateQueries({ queryKey: ['attendance', occurrenceId] })
      queryClient.invalidateQueries({ queryKey: ['fetch-tactical'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-quick-alerts'] })
      queryClient.invalidateQueries({ queryKey: ['get-occurrence-coverage'] })
      queryClient.invalidateQueries({ queryKey: ['occurrences-management'] })
    },
  })

  return {
    mutateCreateIntervention: mutate,
    statusCreateIntervention: status,
  }
}
